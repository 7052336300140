import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

export const config_options = {
  "suppress_alert": "suppress_alert",
  "hide_row": "hide_row",
  "status_tracker": "status_tracker",
  "create_entry": "create_entry",
  "send_email": "send_email",
  "notes": "notes",
  "audit_log": "audit_log"
}

export const statusTrackerConfig_fields = {
  /*
  "statusTrackerConfig": {
      "allowedStatusList": [
          "DRAFT",
          "PENDING",
          "CONFIRMED",
          "APPROVED",
          "POSTED"
      ],
      "defaultStatus": "DRAFT",
      "allowedStatusActionMap": {
          "create_entry": {
              "DRAFT": "APPROVED",
              "PENDING": "APPROVED",
              "CONFIRMED": "APPROVED",
              "APPROVED": "APPROVED",
              "POSTED": "POSTED"
          },
          "send_email": {
              "DRAFT": "PENDING"
          }
      },
      "overrideColName": "override_as_done",
      "overrideColStatus": "POSTED"
  },
  */
  allowedStatusList: "allowedStatusList",
  defaultStatus: "defaultStatus",
  allowedStatusActionMap: "allowedStatusActionMap",
  overrideColName: "overrideColName",
  overrideColStatus: "overrideColStatus"
}

export const createEntryConfig_fields = {
  debitAccountIdListColumn: "debitAccountIdListColumn", 
  debitAccountNameListColumn: "debitAccountNameListColumn", 
  debitAccountAmountListColumn: "debitAccountAmountListColumn", 
  creditAccountIdListColumn: "creditAccountIdListColumn", 
  creditAccountNameListColumn: "creditAccountNameListColumn", 
  creditAccountAmountListColumn: "creditAccountAmountListColumn", 
  jeMemoTextColumn: "jeMemoTextColumn",
  referenceInfoColumn: "referenceInfoColumn",
  accountMappingConfig: "accountMappingConfig",// object
  jeLineMemoListColumn: "jeLineMemoListColumn",
  jeTranDateColumn: "jeTranDateColumn",
  jePostingPeriodIdColumn: "jePostingPeriodIdColumn",
  addReversalFlagColumn: "addReversalFlagColumn",
  reversalPostingPeriodIdColumn: "reversalPostingPeriodIdColumn",
  entryPeriod: "entryPeriod",
  destinationColumn: "destinationColumn",
  entryTypeColumn: "entryTypeColumn",
  reversalTranDateColumn: "reversalTranDateColumn",
  jeSubsidiaryIdColumn: "jeSubsidiaryIdColumn",
  jeSubsidiaryNameColumn: "jeSubsidiaryNameColumn",
  jeDepartmentIdColumn: "jeDepartmentIdColumn",
  jeDepartmentNameColumn: "jeDepartmentNameColumn",
  jeLocationIdColumn: "jeLocationIdColumn",
  jeLocationNameColumn: "jeLocationNameColumn",
  jeDocNumColumn: "jeDocNumColumn",
  jeTranIdColumn: "jeTranIdColumn",
  jeCurrencyColumn: "jeCurrencyColumn",
  jeLineEntityColumn: "jeLineEntityColumn",
  jeClassColumn: "jeClassColumn",
  autoPerformConfig: "autoPerformConfig",// object
  reversalAsSeparateLine: "reversalAsSeparateLine",
  reversalJeMemoTextColumn: "reversalJeMemoTextColumn",
  // Feb 3 - Sandhill update below
  journalLineAllAmountsColumn: "journalLineAllAmountsColumn",
  customCol1Column: "customCol1Column",
  customCol2Column: "customCol2Column",
  customCol3Column: "customCol3Column",
  customCol4Column: "customCol4Column",
  customCol5Column: "customCol5Column",
  // end Sandhill update
  // start periodMappingConfig
  periodMappingConfig: "periodMappingConfig"
  // end periodMappingConfig
}

export const accountMappingConfig_fields = {
  accountMappingTableName: "accountMappingTableName",
  accountMappingNameColumnName: "accountMappingNameColumnName",
  accountMappingNoColumnName: "accountMappingNoColumnName"
}

export const periodMappingConfig_fields = {
  periodMappingTableName: "periodMappingTableName",
  periodMappingIdColumnName: "periodMappingIdColumnName",
  periodMappingNameColumnName: "periodMappingNameColumnName",
  periodMappingEndDateColumnName: "periodMappingEndDateColumnName",
  periodMappingReversalDateColumnName: "periodMappingReversalDateColumnName"
}

export const autoPerformConfig_fields = {
  autoPerformFlagColumn: "autoPerformFlagColumn",
  validAutoPerformStatusList: "validAutoPerformStatusList"
}

export const sendEmailConfig_fields = {
  recipientEmailColumn: "recipientEmailColumn",
  emailSubjectColumn: "emailSubjectColumn",
  emailBodyColumn: "emailBodyColumn",
  uniqueInteractionIdColumn: "uniqueInteractionIdColumn",
  statusToChangeToOnResponse: "statusToChangeToOnResponse",
  statusToChangeFromOnResponse: "statusToChangeFromOnResponse",
  statusToChangeToOnDone: "statusToChangeToOnDone", 
  followUp: "followUp", 
  emailFollowUpBodyColumn: "emailFollowUpBodyColumn", 
  followUpMaxCount: "followUpMaxCount", 
  followUpWaitDays: "followUpWaitDays",
  autoPerformConfig: "autoPerformConfig",
  additionalCcEmailsListColumn: "additionalCcEmailsListColumn"
}

export const rowConfigSetupInfo_fields = {
  [config_options.create_entry]: {
    sourceTableName: "sourceTableName",
    tranDate: "tranDate",
    postingPeriod: "postingPeriod",
    journalMemo: "journalMemo",
    journalLineDebitAccountIds: "journalLineDebitAccountIds",
    journalLineDebitAccountNames: "journalLineDebitAccountNames",
    journalLineDebitAmounts: "journalLineDebitAmounts",
    journalLineCreditAccountIds: "journalLineCreditAccountIds",
    journalLineCreditAccountNames: "journalLineCreditAccountNames",
    journalLineCreditAmounts: "journalLineCreditAmounts",
    addReversalFlag: "addReversalFlag",
    reversalPostingPeriodId: "reversalPostingPeriodId",
    entryPeriod: "entryPeriod",
    destination: "destination",
    entryType: "entryType",
    reversalTranDate: "reversalTranDate",
    subsidiaryId: "subsidiaryId",
    subsidiaryName: "subsidiaryName",
    departmentId: "departmentId",
    departmentName: "departmentName",
    locationId: "locationId",
    locationName: "locationName",
    docNum: "docNum",
    tranId: "tranId",
    currency: "currency",
    lineEntity: "lineEntity",
    class: "class",
    jeLineMemos: "jeLineMemos",
    journalLineDebitAccountNos: "journalLineDebitAccountNos",
    journalLineCreditAccountNos: "journalLineCreditAccountNos",
    reversalAsSeparateLine: "reversalAsSeparateLine",
    reversalJournalMemo: "reversalJournalMemo",
    // Feb 3 - Sandhill update below
    journalLineAllAmounts: "journalLineAllAmounts",
    customCol1: "customCol1",
    customCol2: "customCol2",
    customCol3: "customCol3",
    customCol4: "customCol4",
    customCol5: "customCol5",
    // end Sandhill update
    // start periodMappingConfig
    postingPeriodEndDate: "postingPeriodEndDate"
    // end periodMappingConfig
  },
  [config_options.send_email]: {
    uniqueInteractionId: "uniqueInteractionId",
    recipientEmailAddress: "recipientEmailAddress",
    subject: "subject",
    body: "body",
    statusToChangeToOnAiResponse: "statusToChangeToOnAiResponse",
    statusToChangeFromOnAiResponse: "statusToChangeFromOnAiResponse",
    statusToChangeToOnDone: "statusToChangeToOnDone",
    // followUp fields:
    followUp: "followUp",
    emailFollowUpBody: "emailFollowUpBody",
    followUpMaxCount: "followUpMaxCount",
    followUpWaitDays: "followUpWaitDays",
    additionalCcEmailsList: "additionalCcEmailsList"
  }
}

const dashboardConfigsAdapter = createEntityAdapter({
  selectId: (config) => config.uuid,
});

// TODO: error states??? - how to set/resolve?

export const dashboardConfigsSlice = createSlice({
  name: "dashboardConfigs",
  initialState: dashboardConfigsAdapter.getInitialState(),
  reducers: {
    addConfigs: {
      reducer: dashboardConfigsAdapter.addMany,
      prepare: (configs) => {
        console.log("adding dashboardConfigs ", configs)
        // return {
        //   payload: {
        //     uuid: config.uuid,
        //     companyUuid: config.companyUuid,
        //     createdBy: config.createdBy,
        //     developerAppUuid: config.developerAppUuid,
        //     objectName: config.objectName,
        //     primaryKeyFieldList: config.primaryKeyFieldList,
        //     displayConfigTableRows: config.displayConfigTableRows,
        //     linkedTableForeignFieldMap: config.linkedTableForeignFieldMap,
        //     // what state pieces do we need?
        //     // create_or_modify_row
        //     updateArgs: null,
        //     refresh: false,
        //     error: {
        //       isErrored: true,
        //       message: ""
        //     }
        //   },
        // };
        const processedConfigs = configs.map(config => ({
          uuid: config.uuid,
          companyUuid: config.companyUuid,
          createdBy: config.createdBy,
          developerAppUuid: config.developerAppUuid,
          objectName: config.objectName,
          primaryKeyFieldList: config.primaryKeyFieldList,
          displayConfigTableRows: config.displayConfigTableRows,
          linkedTableForeignFieldMap: config.linkedTableForeignFieldMap,
          sourceTable: config.sourceTable,
          isUpdating: false,
          updateArgs: null,
          resetArgs: null,
          refresh: false,
          error: {
            isErrored: false,
            message: ""
          }
        }));
        // console.log("Adding displayConfigs", processedConfigs);
        return { payload: processedConfigs };
      },
    },
    updateConfig: {
      // set args for update
      reducer: dashboardConfigsAdapter.updateOne,
      prepare: (configUuid, args) => {
        console.log(configUuid + " displayConfig update args", args);
        return {
          payload: {
            id: configUuid,
            changes: {
              updateArgs: args,
              isUpdating: true
            }
          }
        }
      }
    },
    resetConfig: {
      // set reset args
      reducer: dashboardConfigsAdapter.updateOne,
      prepare: (configUuid, args) => {
        console.log(configUuid + " displayConfig reset args", args);
        return {
          payload: {
            id: configUuid,
            changes: {
              resetArgs: args,
              isUpdating: true
            }
          }
        }
      }
    },
    updateConfigTableRows: {
      // insert updated rows
      reducer: dashboardConfigsAdapter.updateOne,
      prepare: (configUuid, newDisplayConfigTableRows) => {
        // console.log(configUuid + " displayConfig new displayConfigTableRows");
        return {
          payload: {
            id: configUuid,
            changes: {
              displayConfigTableRows: newDisplayConfigTableRows,
              updateArgs: null,
              resetArgs: null,
              isUpdating: false
            }
          }
        }
      }
    },
    removeConfigs: {
      // remove all if user/sysadmin leaves dashboard from primary window (got there via email)
      reducer: (state) => {
        console.log("removing all displayConfigs")
        dashboardConfigsAdapter.removeAll(state);
      }
    }
  },
});

export const dashboardConfigsSelectors = dashboardConfigsAdapter.getSelectors((state) => {
  return state.dashboardConfigs;
});

// Action creators are generated for each case reducer function
export const {
  addConfigs,
  updateConfig,
  resetConfig,
  updateConfigTableRows,
  removeConfigs
} = dashboardConfigsSlice.actions;

export default dashboardConfigsSlice.reducer;