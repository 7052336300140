import { useState, useEffect } from "react";

export const view_options = {
	all: "DEFAULT",
	module: "MODULE",
	app: "APP" 
}

export const useToolbar = (selectedCompany) => {
	const [viewOption, setViewOption] = useState(view_options.all);
	
	const handleViewSelection = (newViewOption) => {
		setViewOption(newViewOption);
	}
	
	const [selectedAppUuid, setSelectedAppUuid] = useState("");
	
	const handleAppSelection = (appUuid) => {
		console.log("handleAppSelection - appUuid", appUuid)
		setSelectedAppUuid(appUuid);
	}
	
	const [selectedModuleUuid, setSelectedModuleUuid] = useState("");
	
	const handleModuleSelection = (moduleUuid) => {
		console.log("handleModuleSelection - moduleUuid", moduleUuid)
		setSelectedModuleUuid(moduleUuid);
	}
	
	useEffect(() => {
		if (selectedCompany && selectedCompany.uuid) {
			if (selectedCompany.name) console.log("useToolbar - selected company", selectedCompany.name);
			setSelectedAppUuid("");
			setSelectedModuleUuid("");
		}
	}, [selectedCompany])
	
	useEffect(() => {
		setSelectedAppUuid("");
		setSelectedModuleUuid("");
	}, [viewOption])
	
	return {
		viewOption,
		handleViewSelection,
		selectedAppUuid,
		handleAppSelection,
		selectedModuleUuid,
		handleModuleSelection
	}
}