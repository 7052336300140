import { acct_fields, form_fields, newAccountDict, newCreateEntryForm } from "./createEntryForm";
import { Grid, Typography, IconButton, Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AccountInput from "./AccountInput";
import JournalEntryMemoInput from "./JournalEntryMemoInput";
import AddLineButton from "./AddLineButton";
import Attachments from "./Attachments";
import UpdateButtons from "./UpdateButtons";
import InfoIcon from '@mui/icons-material/Info';
import { getTimeAgo } from "./helpers/getTimeAgo";
import ClientEmailMessage from "./ClientEmailMessage";
import { formatToTwoDecimals } from "./helpers/formatToTwoDecimals";
import { Autocomplete, TextField } from "@mui/material";
import PostingPeriodInput from "./PostingPeriodInput";
import { periodMappingConfig_fields } from '../../../../../../store/dashboardConfigsSlice';
/*
periodMappingTableName: "periodMappingTableName",
  periodIdColumnName: "periodIdColumnName",
  periodNameColumnName: "periodNameColumnName"
*/

////////////////////////////////////////// START ENTRY FORM CREATION
export const generateAccounts = (idsArr, acctsArr, amtsArr, lineMemosArr) => {
  const tempAccounts = [];
  acctsArr.forEach((acctName, index) => {
    const acctId = idsArr[index];
    const acctAmt = amtsArr[index];
    const acctLineMemo = lineMemosArr[index] ?? "";// if not present supply empty string
    const accountDict = newAccountDict(acctId, acctName, acctAmt, acctLineMemo);
    tempAccounts.push(accountDict);
  })
  return tempAccounts;
}

export const populateNewCreateEntryForm = (createEntryConfig, newFormState, row, tables, clientEmail) => {
  console.log("newCreateEntryForm - createEntryConfig", createEntryConfig)
  const { 
    debitAccountIdListColumn, 
    debitAccountNameListColumn, 
    debitAccountAmountListColumn, 
    creditAccountIdListColumn, 
    creditAccountNameListColumn, 
    creditAccountAmountListColumn, 
    jeMemoTextColumn,
    referenceInfoColumn,
    accountMappingConfig,
    jeLineMemoListColumn, 
    periodMappingConfig
  } = createEntryConfig;
  const debitAcctsIds = row[debitAccountIdListColumn]?.split(";") ?? [];
  // console.log("debitAcctsIds", debitAcctsIds)
  const debitAccts = row[debitAccountNameListColumn]?.split(";") ?? [];
  // console.log("debitAccts", debitAccts)
  const debitAmts = row[debitAccountAmountListColumn]?.split(";") ?? [];
  // console.log("debitAmts", debitAmts)
  const creditAcctsIds = row[creditAccountIdListColumn]?.split(";") ?? [];
  // console.log("creditAcctsIds", creditAcctsIds)
  const creditAccts = row[creditAccountNameListColumn]?.split(";") ?? [];
  // console.log("creditAccts", creditAccts)
  const creditAmts = row[creditAccountAmountListColumn]?.split(";") ?? [];
  // console.log("creditAmts", creditAmts)

  const lineMemos = row[jeLineMemoListColumn]?.split(";") ?? [];
  // console.log("lineMemos", lineMemos)
  const debitLineMemos = debitAccts?.length ? lineMemos?.slice(0, debitAccts.length) ?? [] : [];
  // console.log("lineMemos", lineMemos)
  const creditLineMemos = debitAccts?.length ? lineMemos?.slice(debitAccts.length) ?? [] : [];
  // console.log("lineMemos", lineMemos)  

  const referenceInfo = row[referenceInfoColumn] ?? "";
  const memoText = row[jeMemoTextColumn] ?? "";
  newFormState[form_fields.debitAccounts] = generateAccounts(debitAcctsIds, debitAccts, debitAmts, debitLineMemos);
  newFormState[form_fields.creditAccounts] = generateAccounts(creditAcctsIds, creditAccts, creditAmts, creditLineMemos);
  newFormState[form_fields.journalEntryMemo] = memoText;
  newFormState[form_fields.referenceInfo] = referenceInfo;

  // generate accountNameToIdMap
  if (accountMappingConfig) {
    /*
    "accountMappingConfig": {
              "accountMappingTableName": "account_mapping_table",
			        "accountMappingIdColumnName": "account_id",
			        "accountMappingNameColumnName": "account_name"
              "accountMappingNoColumnName": "account_no", // optional
            }
    */
    console.log("accountMappingConfig", accountMappingConfig)
    const table = tables[accountMappingConfig.accountMappingTableName]?.data;
    if (table) {
      // console.log("table", table);
      const accountNameIndex = table[0].indexOf(accountMappingConfig.accountMappingNameColumnName);
      const accountIdIndex = table[0].indexOf(accountMappingConfig.accountMappingIdColumnName);
      // console.log("accountNameIndex", accountNameIndex);
      // console.log("accountIdIndex", accountIdIndex);
      if (accountIdIndex !== -1 && accountNameIndex !== -1) { 
        const rowData = table.slice(1);
        if (rowData.length > 0) {
          const accountNameToIdMap = {};
          rowData.forEach(row => {
            accountNameToIdMap[row[accountNameIndex]] = row[accountIdIndex];
          })
          newFormState[form_fields.accountNameToIdMap] = accountNameToIdMap;
        }
      }
    }
  }

  if (periodMappingConfig) {
    console.log("renderForm - periodMappingConfig", periodMappingConfig)
    const table = tables[periodMappingConfig[periodMappingConfig_fields.periodMappingTableName]]?.data;
    if (table) {
      console.log(`renderForm - periodMappingConfig - table: ${periodMappingConfig[periodMappingConfig_fields.periodMappingTableName]}`, table)
      const periodNameIndex = table[0].indexOf(periodMappingConfig[periodMappingConfig_fields.periodMappingNameColumnName]);
      const periodIdIndex = table[0].indexOf(periodMappingConfig[periodMappingConfig_fields.periodMappingIdColumnName]);
      
      if (periodNameIndex !== -1 && periodIdIndex !== -1) {
        // Create map of name -> id
        const periodNameToIdMap = {};
        let defaultPostingPeriodName = "";
        table.slice(1).forEach(row => {
          periodNameToIdMap[row[periodNameIndex]] = row[periodIdIndex];
          if (row[periodIdIndex] === row[createEntryConfig.jePostingPeriodIdColumn]) {
            defaultPostingPeriodName = row[periodNameIndex];
          }
        });
        if (!defaultPostingPeriodName) {
          defaultPostingPeriodName = table[1][periodNameIndex];// defaults to first available period name if no match found
        }
        // Store the map / set the default period name
        newFormState[form_fields.periodNameToIdMap] = periodNameToIdMap;
        newFormState.postingPeriod = defaultPostingPeriodName;
      }
    }
  }

  // set the 1st credit and debit amount to the response amount
  // set a flag to render notification on form of updated value
  console.log("clientEmail", clientEmail);
  if (clientEmail.uuid) {
    /* && clientEmail.aiReviewState === "llm_processed"
    - removed from if condition above to support case when override values present but llm errored
    - Eric 1/17*/
    // update 1st credit and debit amount if necessary attributes properties are present
    if (clientEmail.attributes && clientEmail.attributes.adjustmentNeeded_override && Object.keys(clientEmail.attributes).includes("variance_override")) {
      // 1st debit
      newFormState[form_fields.debitAccounts] = newFormState[form_fields.debitAccounts].map((dict, index) => {
        if (index === 0) {
          return {
            ...dict,
            [acct_fields.amt]: formatToTwoDecimals(clientEmail.attributes.variance_override)
          }
        } else {
          return dict;
        }
      })
      // 1st credit
      newFormState[form_fields.creditAccounts] = newFormState[form_fields.creditAccounts].map((dict, index) => {
        if (index === 0) {
          return {
            ...dict,
            [acct_fields.amt]: formatToTwoDecimals(clientEmail.attributes.variance_override)
          }
        } else {
          return dict;
        }
      })
    } else if (clientEmail.attributes && !Object.keys(clientEmail.attributes).includes("adjustmentNeeded_override") && clientEmail.attributes.adjustmentNeeded && Object.keys(clientEmail.attributes).includes("variance")) {
      // 1st debit
      newFormState[form_fields.debitAccounts] = newFormState[form_fields.debitAccounts].map((dict, index) => {
        if (index === 0) {
          return {
            ...dict,
            [acct_fields.amt]: formatToTwoDecimals(clientEmail.attributes.variance)
          }
        } else {
          return dict;
        }
      })
      // 1st credit
      newFormState[form_fields.creditAccounts] = newFormState[form_fields.creditAccounts].map((dict, index) => {
        if (index === 0) {
          return {
            ...dict,
            [acct_fields.amt]: formatToTwoDecimals(clientEmail.attributes.variance)
          }
        } else {
          return dict;
        }
      })
    }
    newFormState[form_fields.clientEmail] = clientEmail;
    newFormState[form_fields.clientEmailExpectedAmount] = debitAmts[0] ?? "";
  }
}

export const generateNewCreateEntryForm = (createEntryConfig, row, tables, clientEmail) => {
  // populate form with createEntryConfig
  const newFormState = newCreateEntryForm();
  populateNewCreateEntryForm(createEntryConfig, newFormState, row, tables, clientEmail);
  return newFormState;
}

export const populateSubmittedCreateEntryForm = (createEntryConfig, submittedEntry, newFormState, row, tables, clientEmail) => {
  /*
  {
    "file": {
        "uuid": "erpe_1KOMNARSQCR1Z4F8K5XZAF5GS",
        "companyUuid": "comp_86FOF91CZHPW88SRGR9TYISRB",
        "developerAppUuid": "dapp_DM631F0DMN8Q3C4MJKAYZY3Y4",
        "destination": "netsuite_suiteanalytics",
        "entryType": "journal_entry",
        "entryPeriod": "entryPeriod",
        "entryArgs": {
            "entryType": "journal_entry",
            "journalLineDebitAmounts": "10;10",
            "reversalPostingPeriodId": "11",
            "journalLineDebitAccountNames": "100245 acct_245;100247 acct_247",
            "addReversalFlag": true,
            "destination": "netsuite_suiteanalytics",
            "journalMemo": "Accruing Expense",
            "journalLineCreditAccountNames": "1001 acct_1",
            "journalLineCreditAmounts": "20",
            "journalLineDebitAccountIds": "10245;10247",
            "entryPeriod": "entryPeriod",
            "postingPeriod": "10",
            "tranDate": "2024-01-01",
            "journalLineCreditAccountIds": "101"
        },
        "attachmentLinks": [],
        "message": null,
        "createdOn": "2024-10-08T14:36:52.019+00:00",
        "modifiedOn": null,
        "createdBy": "jack@awsdev_testsandbox.com",
        "hasBeenEntered": false
    }
}
  */
  const {
    referenceInfoColumn,
    accountMappingConfig,
    debitAccountAmountListColumn,
    periodMappingConfig
  } = createEntryConfig;
  const {
    entryArgs
  } = submittedEntry;
  const debitAcctsIds = entryArgs.journalLineDebitAccountIds?.split(";") ?? [];
  const debitAccts = entryArgs.journalLineDebitAccountNames?.split(";") ?? [];
  const debitAmts = entryArgs.journalLineDebitAmounts?.split(";") ?? [];
  const creditAcctsIds = entryArgs.journalLineCreditAccountIds?.split(";") ?? [];
  const creditAccts = entryArgs.journalLineCreditAccountNames?.split(";") ?? [];
  const creditAmts = entryArgs.journalLineCreditAmounts?.split(";") ?? [];

  const lineMemos = entryArgs.jeLineMemos?.split(";") ?? [];
  // console.log("lineMemos", lineMemos)
  const debitLineMemos = debitAccts?.length ? lineMemos?.slice(0, debitAccts.length) ?? [] : [];
  // console.log("lineMemos", lineMemos)
  const creditLineMemos = debitAccts?.length ? lineMemos?.slice(debitAccts.length) ?? [] : [];
  // console.log("lineMemos", lineMemos)  

  const referenceInfo = row[referenceInfoColumn] ?? "";
  const memoText = entryArgs.journalMemo ?? "";
  newFormState[form_fields.previouslySubmitted] = true;
  newFormState[form_fields.debitAccounts] = generateAccounts(debitAcctsIds, debitAccts, debitAmts, debitLineMemos);
  newFormState[form_fields.creditAccounts] = generateAccounts(creditAcctsIds, creditAccts, creditAmts, creditLineMemos);
  newFormState[form_fields.journalEntryMemo] = memoText;
  newFormState[form_fields.referenceInfo] = referenceInfo;

  if (accountMappingConfig) {
    const table = tables[accountMappingConfig.accountMappingTableName]?.data;
    if (table) {
      const accountNameIndex = table[0].indexOf(accountMappingConfig.accountMappingNameColumnName);
      const accountIdIndex = table[0].indexOf(accountMappingConfig.accountMappingIdColumnName);
      if (accountIdIndex !== -1 && accountNameIndex !== -1) { 
        const rowData = table.slice(1);
        if (rowData.length > 0) {
          const accountNameToIdMap = {};
          rowData.forEach(row => {
            accountNameToIdMap[row[accountNameIndex]] = row[accountIdIndex];
          })
          newFormState[form_fields.accountNameToIdMap] = accountNameToIdMap;
        }
      }
    }
  }

  if (periodMappingConfig) {
    const table = tables[periodMappingConfig[periodMappingConfig_fields.periodMappingTableName]]?.data;
    if (table) {
      const periodNameIndex = table[0].indexOf(periodMappingConfig[periodMappingConfig_fields.periodMappingNameColumnName]);
      const periodIdIndex = table[0].indexOf(periodMappingConfig[periodMappingConfig_fields.periodMappingIdColumnName]);
      
      if (periodNameIndex !== -1 && periodIdIndex !== -1) {
        const submittedPostingPeriod = entryArgs.postingPeriod;
        let submittedPostingPeriodName = "";
        const periodNameToIdMap = {};
        table.slice(1).forEach(row => {
          periodNameToIdMap[row[periodNameIndex]] = row[periodIdIndex];
          if (row[periodIdIndex] === submittedPostingPeriod) {
            submittedPostingPeriodName = row[periodNameIndex];
          }
        });
        // Only store the map / set the posting period name
        newFormState[form_fields.periodNameToIdMap] = periodNameToIdMap;
        newFormState.postingPeriod = submittedPostingPeriodName;
      }
    }
  }

  console.log("clientEmail", clientEmail);
  if (clientEmail.uuid) {
    /* && clientEmail.aiReviewState === "llm_processed" 
    - removed from if condition above to support override values if aiReviewState is errored
    - Eric 1/17*/
    newFormState[form_fields.clientEmail] = clientEmail;
    const initialDebitAmts = row[debitAccountAmountListColumn]?.split(";") ?? [];
    newFormState[form_fields.clientEmailExpectedAmount] = initialDebitAmts[0] ?? "";
  }
}

export const generateSubmittedCreateEntryForm = (createEntryConfig, submittedEntry, row, tables, clientEmail) => {
  const newFormState = newCreateEntryForm();
  populateSubmittedCreateEntryForm(createEntryConfig, submittedEntry, newFormState, row, tables, clientEmail);
  return newFormState;
}

const addLine = (form_field, setFormState) => {
  // add empty newAcctDict to form_field
  const newAccount = newAccountDict("", "", "", "");
  setFormState((prevFormState) => {
    return {
      ...prevFormState, // Spread the previous state
      [form_field]: [...prevFormState[form_field], newAccount] // Append the new account to the form_field array
    };
  })
}
////////////////////////////////////////// END ENTRY FORM CREATION


export const renderForm = (formState, setFormState, handleSubmit, handleDelete, handleClose, createEntryConfig) => {
  console.log("renderForm - formState", formState);
  return (
    <Grid container spacing={1} sx={{ padding: "10px", position: "relative"}} alignItems="flex-start">
      <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>{formState[form_fields.previouslySubmitted] ? "Update " : "Create "}journal entry</Typography>
        <IconButton onClick={handleClose} sx={{ padding: "4px" }}>
          <CloseIcon/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography>{formState[form_fields.referenceInfo]}</Typography>
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 500}}>Debit accounts</Typography>
      </Grid>
      {formState[form_fields.debitAccounts].map((debitAcctDict, index) => {
        return (
          <AccountInput
            key={index + "debit_account_input"}
            acctDict={debitAcctDict}
            index={index}
            form_field={form_fields.debitAccounts}
            setFormState={setFormState}
            accountNameToIdMap={formState[form_fields.accountNameToIdMap]}
          />
        )
      })}
      <AddLineButton
          form_field={form_fields.debitAccounts}
          setFormState={setFormState}
          addLine={addLine}
        />
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 500}}>Credit accounts</Typography>
      </Grid>
      {formState[form_fields.creditAccounts].map((creditAcctDict, index) => {
        return (
          <AccountInput
            key={index + "credit_account_input"}
            acctDict={creditAcctDict}
            index={index}
            form_field={form_fields.creditAccounts}
            setFormState={setFormState}
            accountNameToIdMap={formState[form_fields.accountNameToIdMap]}
          />
        )
      })}
      <AddLineButton
        form_field={form_fields.creditAccounts}
        setFormState={setFormState}
        addLine={addLine}
      />
      {formState[form_fields.periodNameToIdMap] && (
        <>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 500, mt: 2 }}>Posting Period</Typography>
          </Grid>
          <PostingPeriodInput
            formState={formState}
            setFormState={setFormState}
          />
        </>
      )}
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 500}}>Journal entry memo</Typography>
      </Grid>
      <JournalEntryMemoInput
        memo={formState[form_fields.journalEntryMemo]}
        setFormState={setFormState}
      />
      {/* end new */}
      {/* old*** was here */}
      
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <ClientEmailMessage
        clientEmail={formState[form_fields.clientEmail]}
        clientEmailExpectedAmount={formState[form_fields.clientEmailExpectedAmount]}
      />
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <UpdateButtons
        formState={formState}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
      />
    </Grid>
  )
}
//*** old:
//<Grid container item xs={8} spacing={1}>
//        <Grid item xs={12}>
//          <Typography sx={{ fontWeight: 500}}>Debit accounts</Typography>
//        </Grid>
//      {formState[form_fields.debitAccounts].map((debitAcctDict, index) => {
// //          return (
//             <AccountInput
//               key={index + "_account_input"}
//               acctDict={debitAcctDict}
//               index={index}
//               form_field={form_fields.debitAccounts}
//               setFormState={setFormState}
//               accountNameToIdMap={formState[form_fields.accountNameToIdMap]}
//             />
//           )
//         })}
//         <AddLineButton
//           form_field={form_fields.debitAccounts}
//           setFormState={setFormState}
//           addLine={addLine}
//         />
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: 500}}>Credit accounts</Typography>
//         </Grid>
//         {formState[form_fields.creditAccounts].map((creditAcctDict, index) => {
//           return (
//             <AccountInput
//               key={index + "_account_input"}
//               acctDict={creditAcctDict}
//               index={index}
//               form_field={form_fields.creditAccounts}
//               setFormState={setFormState}
//               accountNameToIdMap={formState[form_fields.accountNameToIdMap]}
//             />
//           )
//         })}
//         <AddLineButton
//           form_field={form_fields.creditAccounts}
//           setFormState={setFormState}
//           addLine={addLine}
//         />
//       </Grid>
//       <Grid container item xs={4} spacing={1}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: 500}}>Journal entry memo</Typography>
//         </Grid>
//         <JournalEntryMemoInput
//           memo={formState[form_fields.journalEntryMemo]}
//           setFormState={setFormState}
//         />
//         <Grid item xs={12}>
//           {/* purely for spacing */}
//         </Grid>
//         {/* Eric 9/26 - currently hidden, awaiting server solution for attachments */}
//         {/* <Grid item xs={12}>
//           <Typography sx={{ fontWeight: 500}}>Attachments</Typography>
//         </Grid>
//         <Attachments
//           attachedFiles={formState[form_fields.attachedFiles]}
//           setFormState={setFormState}
//         /> */}
//       </Grid>