import { Tooltip, IconButton, Modal, Box, Grid, Button, Typography, FormControl, TextField } from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { getIconButtonStyle } from "./ConfigActionUtils";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { config_options } from "../../../../../store/dashboardConfigsSlice";
import { useState } from "react";
import * as Constants from "../../../../../Constants";
import { objInBody } from "../DisplayConfigUpdateUtils";
import DirectRequest from "../../../../../API/requests/DirectRequest";
import iso8601Timestamp from "../../../../../helpers/iso8601Timestamp";
/*
handleConfigUpdate={handleConfigUpdate}
        configAction={configAction}
        displayConfigTableRow={displayConfigTableRow}
        sendEmailConfig={sendEmailConfig}
        statusTrackerConfig={statusTrackerConfig}
        disableActions={disableActions}
        row={row}
        sourceTable={sourceTable}
*/
const getColumnValue = (row, column) => {
  if (!column) return "No value found"
  return row[column] ?? "No value found"
}

const RecipientAddressInput = ({ value, error, errorMessage, handleChange }) => {
  const label = "To:"
  return (
    <FormControl size="small" sx={{ width: "300px" }}>
      <TextField
        size="small"
        variant="standard"
        label={label}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        error={error}
        helperText={errorMessage}
        InputLabelProps={{
          sx: { fontWeight: 'bold', fontSize: "16px !important", color: "black" }
        }}
      />
    </FormControl>
  );
}

const SendEmailForm = ({ row, sendEmailConfig, handleSubmit, handleClose }) => {
  /*
  sendEmailConfig: {
    recipientEmailColumn: str,
    wlEmailCcColumn: str,
    emailSubjectColumn: str,
    emailBodyColumn: str,
    uniqueInteractionIdColumn: str,
    statusToChangeToOnResponse: str,
    variableColumnMap: Dict[str, str] = None,
    additionalCcEmailsListColumn: str = None
  }
  new (12/18/2024) sendEmailConfig: {
    recipientEmailColumn="recipientEmailColumn", 
    emailSubjectColumn="emailSubjectColumn", 
    emailBodyColumn="emailBodyColumn", 
    uniqueInteractionIdColumn="uniqueInteractionIdColumn", 
    statusToChangeFromOnResponse="PENDING", 
    statusToChangeToOnResponse="CONFIRMED", 
    statusToChangeToOnDone="POSTED", 
    followUp=True, 
    emailFollowUpBodyColumn="emailFollowUpBodyColumn", 
    followUpMaxCount=3, 
    followUpWaitDays=2
  }
  */
  const {
    recipientEmailColumn,
    emailSubjectColumn,
    emailBodyColumn,
    uniqueInteractionIdColumn,
    statusToChangeToOnResponse,
    statusToChangeFromOnResponse,
    // variableColumnMap: Dict[str, str] = None, -> NOT IN USE YET
    additionalCcEmailsListColumn
  } = sendEmailConfig;

  const [recipientAddress, setRecipientAddress] = useState(row[recipientEmailColumn] ?? "");

  const handleRecipientAddressChange = (newValue) => {
    setRecipientAddress(newValue)
  }

  const error = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(recipientAddress);

  const errorMessage = error ? "Please enter a valid email address" : "";

  return (
    <Grid container spacing={1} sx={{ padding: "10px", position: "relative"}} alignItems="flex-start">
      <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Send email</Typography>
        <IconButton onClick={handleClose} sx={{ padding: "4px" }}>
          <CloseIcon/>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12}>
        {/* <Typography><strong>To:&nbsp;</strong>{getColumnValue(row, recipientEmailColumn)}</Typography> */}
        {/* value, error, errorMessage, handleChange */}
        <RecipientAddressInput
          value={recipientAddress}
          error={error}
          errorMessage={errorMessage}
          handleChange={handleRecipientAddressChange}
        />
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12}>
        <Typography><strong>Subject:&nbsp;</strong>{getColumnValue(row, emailSubjectColumn)}</Typography>
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12}>
        <Typography><strong>Body:</strong></Typography>
      </Grid>
      <Grid item xs={12}>
        <div 
          style={{ 
            // backgroundColor: '#fff',
            padding: '8px',
            // border: '1px solid #eee',
            // borderRadius: '4px',
            // margin: '8px 0'
          }}
        >
          <div
            dangerouslySetInnerHTML={{ 
              __html: getColumnValue(row, emailBodyColumn) 
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        {/* purely for spacing */}
      </Grid>
      <Grid item xs={12} container justifyContent={"center"} alignItems={"center"}>
        {/* confirm button here */}
        <Button
          variant="contained"
          disabled={error}
          onClick={() => handleSubmit(recipientAddress)}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  )
}

export const SendEmailConfigActionButton = ({handleConfigUpdate, displayConfigTableRow, sendEmailConfig, statusTrackerConfig, disableActions, row, sourceTable}) => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.role.name);
  const isInternal = useSelector((state) => state.role.isInternal);
  const dashboardUuid = useSelector(state => state.dashboard.uuid);
  const [emailSentForCreatedEntryArgs, setEmailSentForCreatedEntryArgs] = useState(null);

  const handleToggle = () => {
    if (!sendEmailConfig) return;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
      setOpen(false);
  };

  const handleSubmit = (recipientAddress) => {
    // generate send email args
    const rowConfigSetupInfo = {};
    // map preoperties from sendEmailConfig into rowConfigSetupInfo
    const {
      recipientEmailColumn,
      emailSubjectColumn,
      emailBodyColumn,
      uniqueInteractionIdColumn,
      statusToChangeToOnResponse,
      statusToChangeFromOnResponse,
      statusToChangeToOnDone, 
      followUp, 
      emailFollowUpBodyColumn, 
      followUpMaxCount, 
      followUpWaitDays,
      // variableColumnMap: Dict[str, str] = None, -> NOT IN USE YET
      additionalCcEmailsListColumn
    } = sendEmailConfig;
    /*
    saveClientEmail(companyUuid, developerAppUuid, displayConfigTableRow.getUuid(), rowConfigSetupInfo, createdBy);

    private final String UNIQUE_INTERACTION_ID_FIELD = "uniqueInteractionId";
    private final String RECIPIENT_EMAIL_ADDRESS_FIELD = "recipientEmailAddress";
    // -> refactored out: private final String WL_CC_EMAIL_ADDRESS_FIELD = "wlCcEmailAddress";
    private final String ADDITIONAL_CC_EMAIL_ADDRESSES_FIELD = "additionalCcEmailAddresses";
    private final String SUBJECT_FIELD = "subject";
    private final String BODY_FIELD = "body";
    private final String STATUS_ON_AI_RESPONSE_FIELD = "statusToChangeToOnAiResponse";
    // -> refactored out: private final String ENTRY_PERIOD_FIELD = "entryPeriod";
    private final String ATTRIBUTES_FIELD = "attributes";

    String uniqueInteractionId = (String) rowConfigSetupInfo.get(UNIQUE_INTERACTION_ID_FIELD);
    String recipientEmailAddress = (String) rowConfigSetupInfo.get(RECIPIENT_EMAIL_ADDRESS_FIELD);
    // -> refactored out: String wlCcEmailAddress = (String) rowConfigSetupInfo.get(WL_CC_EMAIL_ADDRESS_FIELD);
    String additionalCcEmailAddressesRaw = (String) rowConfigSetupInfo.get(ADDITIONAL_CC_EMAIL_ADDRESSES_FIELD);
    String subject = (String) rowConfigSetupInfo.get(SUBJECT_FIELD);
    String body = (String) rowConfigSetupInfo.get(BODY_FIELD);
    String statusToChangeToOnAiResponse = (String) rowConfigSetupInfo.get(STATUS_ON_AI_RESPONSE_FIELD);
    // -> refactored out: String entryPeriod = (String) rowConfigSetupInfo.get(ENTRY_PERIOD_FIELD);
    
    new (12/18/2024) sendEmailConfig: {
      recipientEmailColumn="recipientEmailColumn", 
      emailSubjectColumn="emailSubjectColumn", 
      emailBodyColumn="emailBodyColumn", 
      uniqueInteractionIdColumn="uniqueInteractionIdColumn", 
      statusToChangeFromOnResponse="PENDING", 
      statusToChangeToOnResponse="CONFIRMED", 
      statusToChangeToOnDone="POSTED", 
      followUp=True, 
      emailFollowUpBodyColumn="emailFollowUpBodyColumn", 
      followUpMaxCount=3, 
      followUpWaitDays=2
    }
    */
    rowConfigSetupInfo["uniqueInteractionId"] = row[uniqueInteractionIdColumn];
    rowConfigSetupInfo["recipientEmailAddress"] = recipientAddress;//row[recipientEmailColumn];
    rowConfigSetupInfo["subject"] = row[emailSubjectColumn];
    rowConfigSetupInfo["body"] = row[emailBodyColumn];
    rowConfigSetupInfo["statusToChangeToOnAiResponse"] = statusToChangeToOnResponse;
    rowConfigSetupInfo["statusToChangeFromOnAiResponse"] = statusToChangeFromOnResponse;
    // new fields - 12/18/2024
    rowConfigSetupInfo["statusToChangeToOnDone"] = statusToChangeToOnDone;
    if (followUp) {
      rowConfigSetupInfo["followUp"] = followUp;
      rowConfigSetupInfo["emailFollowUpBody"] = row[emailFollowUpBodyColumn];
      rowConfigSetupInfo["followUpMaxCount"] = followUpMaxCount;
      rowConfigSetupInfo["followUpWaitDays"] = followUpWaitDays;
    }
    if (additionalCcEmailsListColumn && row[additionalCcEmailsListColumn]) {
      rowConfigSetupInfo["additionalCcEmailsList"] = row[additionalCcEmailsListColumn]
    }

    const newConfigValue = { rowConfigSetupInfo: rowConfigSetupInfo };

    // if user submits and email for a row which already has a create_entry optionValue, notify the team
    if (!isInternal && displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.create_entry]?.optionValue) {
      const rowInBody = objInBody(row); 
      const displayConfigRowInBody = objInBody(displayConfigTableRow);
      const tempEmailSentForCreatedEntryArgs = {
        url: Constants.SERVER_SEND_EMAIL,
        method: "POST",
        body: JSON.stringify({
          "body": `<strong>${user}</strong> sent an email for a row which already has a created journal entry<br/><br/>
            appUuid: ${dashboardUuid}<br/><br/>
            sourceTable: ${sourceTable}<br/><br/>
            row: ${rowInBody}<br/><br/>
            displayConfigTableRow: ${displayConfigRowInBody}<br/><br/>
            on ${window.location.href}<br/><br/>`,
          "subject": `${user} sent an email for a row which already has a created journal entry (${window.location.origin} - ${iso8601Timestamp()})`,
          "from": "noreply@wiselayer.com",
          "toEmail": Constants.notificationList.join(","),
          "sendHTML": true
        })
      }
      setEmailSentForCreatedEntryArgs(tempEmailSentForCreatedEntryArgs);
    }

    handleConfigUpdate(newConfigValue);
    setOpen(false);
  }

  const disabled = () => {
    if (disableActions) {
      return true;
    } else if (!sendEmailConfig) {
      return true;
    } else if (row[statusTrackerConfig?.overrideColName]) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={emailSentForCreatedEntryArgs}
        afterProcess={() => setEmailSentForCreatedEntryArgs(null)}
      />
      <Tooltip title={"Send email"} followCursor>
        <span> {/* Wrapping in a span to enable tooltip on disabled button */}
          <IconButton
            tabIndex={-1}
            sx={getIconButtonStyle(config_options.send_email)}
            onClick={handleToggle}
            disabled={disabled()}
          >
            {/* {getIcon(null, configAction, disableActions)} */}
            <ForwardToInboxIcon/>
          </IconButton>
        </span>
      </Tooltip>

      {/* Modal component */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="create-entry-modal-title"
        aria-describedby="create-entry-modal-description"
      >
        {/* Style for modal content */}
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800, // You can adjust the size as needed
            maxHeight: '100vh', // Limit the maximum height to 90% of the viewport height
            overflowY: 'auto', // Enable vertical scrolling if content overflows
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 3,
            // borderRadius: 1, // Optional, for a rounded corner effect
          }}
        >
          <SendEmailForm
            row={row}
            sendEmailConfig={sendEmailConfig}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </>
  );
}

export default SendEmailConfigActionButton;