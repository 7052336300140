/*
module:
		String uuid;
		String companyUuid;
		String externalUuid;
		String name;
		String createdBy;

		String fullName;
		String description;

		// The apps that should have visual tabs;
		// there may be apps run in the dependency graph that are not displayed and thus not included in this list.
		List<String> displayApps;
		List<String> displayTitles;

		// a map describing the dependency graph of the module.
		// There should always be one or more nodes that are "root" nodes that are the first apps to be added to the queue
		// When all parent nodes' jobs have finished, then the child node should be added to the job queue.
		// Todo: Junaid decide what directed graph implementation to use
		DirectedGraph<String> appDependencyGraph;

		List<String> tags;

		List<String> visibleToCompanyUuids;
		List<String> hideFromCompanyUuids;
*/
//create a tabs component
// needs to accept a property called module and updateState
// updateState accepts an object - we will set uuid as a property in that object, it will be the displayApp the user clicked on
import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DirectRequest from "../../API/requests/DirectRequest";
import * as Constants from "../../Constants";
import {useState} from "react";
import objInBody from "../../helpers/objInBody";
import iso8601Timestamp from "../../helpers/iso8601Timestamp";
import {setAlertNotificationArgs} from "../../store/alertsNotificationSlice";
import {module_fields} from "../../store/modulesSlice";

const generateModuleAppViewNotificationArgs = (module, app, user) => {
	/*
	const tempArgs = {
		url: Constants.SERVER_SEND_EMAIL,
		method: "POST",
		body: JSON.stringify({
			"body": `Message: ${message}<br/><br/><br/>${getUnmatchedAccountNames(unmatchedAccountNames)}Row:<br/>${rowInBody}<br/><br/>App: ${appUuid} on ${window.location.href}<br/><br/>`,
			"subject": `accountMappingConfig error for ${user} viewing ${appUuid} on ${window.location.href} (${window.location.origin} - ${iso8601Timestamp()})`,
			"from": "noreply@wiselayer.com",
			"toEmail": Constants.notificationList.join(","),
			"sendHTML": true
		})
	}
	*/
	const {
		fullName,
		displayApps,
		displayTitles
	} = module
	const displayTitleIndex = displayApps.indexOf(app.uuid);
	const displayTitle = displayTitles[displayTitleIndex];
	const tempArgs = {
		url: Constants.SERVER_SEND_EMAIL,
		method: "POST",
		body: JSON.stringify({
			"body": `${user} selected the ${displayTitle} app in the ${fullName} module on ${window.location.href}<br/><br/>module: ${objInBody(module)}<br/><br/>app: ${objInBody(app)}`,
			"subject": `${user} selected the ${displayTitle} app in the ${fullName} module (${window.location.origin} - ${iso8601Timestamp()})`,
			"from": "noreply@wiselayer.com",
			"toEmail": Constants.notificationList.join(","),
			"sendHTML": true
		})
	}
	return tempArgs;
}

const tabStyles = {
	tabContainer: {
		padding: '12px 24px',
		borderBottom: '1px solid #e1e1e1',
		gap: '32px'
	},
	tab: {
		cursor: 'pointer',
		padding: '4px 0',
		fontSize: '14px',
		fontWeight: '400',
		color: '#666',
		transition: 'all 0.2s ease',
		height: '100%',
	},
	activeTab: {
		color: '#000',
		borderBottom: '2px solid #5C4DC9',
		marginBottom: '-1px'
	}
};

const ModuleTabs = ({ state, module, cancelAction }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const apps = useSelector(state => state.apps.filteredList);
	const subscribedApps = useSelector(state => state.appsSubscriptions.list);
	
	const allApps = useSelector((state) => state.appsSysAdmin.list);
	const [companyAppsSubscriptions, setCompanyAppsSubscriptions] = useState([]);
	const [companyAppsSubscriptionsArgs, setCompanyAppsSubscriptionsArgs] = useState(
		state?.companyUuid 
			? { url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + state.companyUuid } 
			: null
	);
	
	// const isSysAdmin = useSelector((state) => state.role.isSysAdmin);
	const user = useSelector((state) => state.role.name);
	const isInternal = useSelector(state => state.role.isInternal);
	
	// Early return if no module or display apps
	if (!module?.[module_fields.displayApps] || !module?.[module_fields.displayTitles]) {
		return null;
	}
	
	const handleCompanyAppsSubscriptions = (res) => {
		console.log("ModuleTabs - companySubscribedApps", res);
		setCompanyAppsSubscriptions([...res])
	}
	
	const handleTabClick = (appUuid, index) => {
		if (state.companyUuid) {
			console.log("ModuleTabs - sysadmin -> ", "appUuid", appUuid, "displayTitle", module[module_fields.displayTitles][index]);
			navigate('.', {
					replace: true, // Replace current history entry instead of adding new one
					state: {
							...state,
							uuid: appUuid,
							viewModule: true,
							moduleUuid: state.moduleUuid,
							customerSubscriptionUuid: companyAppsSubscriptions.find(sub => sub.developerAppUuid === appUuid).uuid,
							companyUuid: state.companyUuid,
							thisDevApp: allApps.find(app => app.uuid === appUuid)
					}
			});
		} else {
			console.log("ModuleTabs - user -> ", "appUuid", appUuid, "displayTitle", module[module_fields.displayTitles][index]);
			if (!isInternal) {
				dispatch(setAlertNotificationArgs(generateModuleAppViewNotificationArgs(state.module, apps.find(app => app.uuid === appUuid), user)))
			}
			navigate('.', {
					replace: true, // Replace current history entry instead of adding new one
					state: {
							...state,
							uuid: appUuid,
							viewModule: true,
							moduleUuid: state.moduleUuid,
							customerSubscriptionUuid: subscribedApps.find(sub => sub.developerAppUuid === appUuid).uuid,
							companyUuid: state.companyUuid,
							thisDevApp: apps.find(app => app.uuid === appUuid)
					}
			});
		}
	};

	return (
		<>
			<DirectRequest requestArgs={companyAppsSubscriptionsArgs} afterProcess={handleCompanyAppsSubscriptions}/>
			<div style={{ 
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				gap: '16px',
				padding: '8px',
				marginTop: "-18px",// accounting for vertical padding div in App.js' WrappedComponent
				marginBottom: "8px",
				borderBottom: '1px solid #e1e1e1'
			}}>
				{module[module_fields.displayApps].map((appUuid, index) => (
					<div
						key={appUuid + "_module_tab"}
						onClick={() => handleTabClick(appUuid, index)}
						style={{
							...tabStyles.tab,
							...(state.uuid === appUuid ? tabStyles.activeTab : {})
						}}
					>
						{module[module_fields.displayTitles][index]}
					</div>
				))}
			</div>
		</>
	);
};

export default ModuleTabs;