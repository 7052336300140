import React, {useMemo, useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import FeedTable from "../../AlertFeed/FeedTable";
import {
	appNameDisplay,
	flagDescription,
	moneySaved,
	flagAiRelevantStatus,
	actions,
	flagAiMessage,
	flagAiMessageSysadmin,
	lastActionTaken,
	flagRowId,
	flagOverrideAndShowAnyway,
	referenceDescription, flagFirstOccurrenceTimestamp
} from "../../AlertFeed/FeedTableColumns";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";

const LastSync = ({ lastSyncData }) => {
	return (
		<Typography
			sx={{
				position: "absolute",
				bottom: 20,
				left: 26,
				color: "rgba(0, 0, 0, 0.54) !important",
				fontWeight: "600"
			}}
		>
			Last sync:&nbsp;&nbsp;
			{new Date(lastSyncData.createdOn).toLocaleString(undefined, {
				month: 'long',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
				timeZoneName: 'short'
			})
			}
		</Typography>
	)
}

const LastSyncSysAdmin = ({ developerAppUuid, companyUuid }) => {
	const isSysAdmin = useSelector(state => state.role.isSysAdmin);
	const [lastSyncArgs, setLastSyncArgs] = useState(
		{ url: Constants.SERVER_SYSADMIN_POST_GET_ALERT_SYNCS_URL + companyUuid, method: "POST", body: JSON.stringify({}) }
	);
	const [alertSyncs, setAlertSyncs] = useState({});
	const lastSyncData = alertSyncs[developerAppUuid];
	const handleAlertSyncs = (res) => {
		console.log(`alertSyncs for ${companyUuid}`, res);
		if (res && res.alertSyncEventMap) {
			setAlertSyncs(res.alertSyncEventMap);
		}
	}
	return isSysAdmin && companyUuid ? (
		<>
			<DirectRequest
				requestArgs={lastSyncArgs}
				afterProcess={handleAlertSyncs}
				handleError={err => console.log("error getting alert syncs", err)}
				handleCatchError={err => console.log("client error getting alert syncs", err)}
			/>
			{lastSyncData && <LastSync lastSyncData={lastSyncData} />}
		</>
	) : null;
}

const LastSyncUser = ({ developerAppUuid }) => {
	const alertSyncs = useSelector(state => state.alertSyncs.syncMap);
	const lastSyncData = alertSyncs[developerAppUuid];
	return lastSyncData ? (
		<LastSync lastSyncData={lastSyncData} />
	) : null;
}

const LastSyncPicker = ({ developerAppUuid, companyUuid }) => {
	return !companyUuid ? (
		<LastSyncUser developerAppUuid={developerAppUuid}/>
	) : (
		<LastSyncSysAdmin developerAppUuid={developerAppUuid} companyUuid={companyUuid} />
	)
}

// Prev-prev props: /*alerts,*/
// Prev props: hideAlerts, preview, developerAppUuid, highlightAlertUuid, companyUuid
function FDAlertFeed({state}) {
	const dispatch = useDispatch();
	const theme = useTheme();
	
	const {
		preview,
		developerAppUuid,
		highlightAlertUuid,
		companyUuid,
		showAlerts
	} = useMemo(() => ({
		preview: state.preview,
		developerAppUuid: state.thisDevApp?.uuid,
		highlightAlertUuid: state.thisAlert?.uuid,
		companyUuid: state.companyUuid,
		showAlerts: state.thisDevApp?.tags ? !state.thisDevApp.tags.includes("view_config__hide_alerts") : false// default to false unless !hide_alerts
	}), [state]);

	const alerts = useSelector((state) => state.previewApp.alerts);

	const userAlerts = useSelector((state) => state.alerts.alerts);
	const userResolvedAlerts = useSelector((state) => state.alerts.resolvedAlerts);
	const sysAdminAlerts = useSelector((state) => state.alertsSysAdmin.alerts);
	const sysAdminResolvedAlerts = useSelector((state) => state.alertsSysAdmin.resolvedAlerts);

	const selectedTab = useSelector((state) => state.alertsTabs.selectedTab);
	const selectedTabSysAdmin = useSelector((state) => state.alertsTabsSysAdmin.selectedTab);

	const filters = useSelector((state) => state.alertsTabs.filters);
	const sysAdminFilters = useSelector((state) => state.alertsTabsSysAdmin.filters);
	const sysAdminUserFilters = useSelector((state) => state.alertsTabsSysAdmin.userFilters);
	const readyForCustomer = useSelector((state) => state.alertsTabsSysAdmin.readyForCustomer);

	const cleanAlerts = (alerts) => {
		return alerts.map((alert, index) => {
			return {
				...alert,  // Preserve original properties
				Id: index, // Add Id property
				moneySaved: alert.flagMoneySaved //parseFloat(alert.flagMoneySaved) || 0, // Add moneySaved property (preview alerts send flagMoneySaved as a string)
			};
		})
	}

	const getAlerts = (
		preview,
		developerAppUuid,
		highlightAlertUuid,
		companyUuid,
		alerts,
		userAlerts,
		userResolvedAlerts,
		sysAdminAlerts,
		sysAdminResolvedAlerts,
	) => {
		if (preview) {
			return cleanAlerts(alerts)
		} else {
			if (!companyUuid) {
				const selectedAlerts = selectedTab === "Resolved" ? filters[selectedTab](userResolvedAlerts) : filters[selectedTab](userAlerts);
				const selectedAppAlerts = selectedAlerts.filter((alert) => alert.developerAppUuid === developerAppUuid);
				if (highlightAlertUuid) {
					return selectedAppAlerts.filter((alert) => alert.uuid === highlightAlertUuid)
				} else {
					return selectedAppAlerts
				}
			} else {
				let selectedSysAdminAlerts;
				let selectedSysAdminAppAlerts;
				if (readyForCustomer) {
					selectedSysAdminAlerts = selectedTabSysAdmin === "Resolved" ? sysAdminUserFilters[selectedTabSysAdmin](sysAdminResolvedAlerts) : sysAdminUserFilters[selectedTabSysAdmin](sysAdminAlerts);
				} else {
					selectedSysAdminAlerts = selectedTabSysAdmin === "Resolved" ? sysAdminResolvedAlerts : sysAdminFilters[selectedTabSysAdmin](sysAdminAlerts);
				}
				selectedSysAdminAppAlerts = selectedSysAdminAlerts.filter((alert) => alert.developerAppUuid === developerAppUuid);
				if (highlightAlertUuid) {
					return selectedSysAdminAppAlerts.filter((alert) => alert.uuid === highlightAlertUuid)
				} else {
					return selectedSysAdminAppAlerts
				}
			}
		}
	}

	const columns = useMemo(() => {
		if (preview) {
			// sysAdmin previewing dashboard - selectedTab/selectedTabSysAdmin & readyForCustomer don't matter - alerts will be pulled from getData return
			return [
				appNameDisplay(selectedTab, true, preview),
				moneySaved(true, selectedTab, true),
				flagDescription([], [], "", selectedTab, readyForCustomer, true, true, preview),
				referenceDescription(true, selectedTab, preview, true),
			]
		} else if (companyUuid) {
			// sysAdmin viewing user alert dashboard - selectedTabSysAdmin & readyForCustomer matter
			return [
				appNameDisplay(selectedTabSysAdmin, true, false),
				moneySaved(true, selectedTabSysAdmin, false),
				flagDescription([], [], companyUuid, selectedTabSysAdmin, readyForCustomer, true, true, false),
				referenceDescription(true, selectedTabSysAdmin, false, true),
				flagFirstOccurrenceTimestamp(true),
				actions(true, companyUuid, selectedTabSysAdmin),
				flagAiRelevantStatus(true, companyUuid, selectedTabSysAdmin),
				flagAiMessage(),
				flagAiMessageSysadmin(),
				lastActionTaken(),
				flagRowId(),
			].concat(selectedTabSysAdmin === 'Main' || selectedTabSysAdmin === 'Unread' ? [flagOverrideAndShowAnyway()] : []);
		} else {
			if (selectedTab === "Resolved") {
				// user viewing user resolved alert dashboard
				return [
					appNameDisplay(selectedTab, false, false),
					moneySaved(false, selectedTab, false),
					flagDescription([], [], "", selectedTab, false, true, false, false),
					referenceDescription(false, selectedTab, false, true),
					flagFirstOccurrenceTimestamp(false),
				]
			}
			// user viewing user alert dashboard
			return [
				appNameDisplay(selectedTab, false, false),
				moneySaved(false, selectedTab, false),
				flagDescription([], [], "", selectedTab, false, true, false, false),
				referenceDescription(false, selectedTab, false, true),
				flagFirstOccurrenceTimestamp(false),
				actions(false, "", selectedTab),
				flagAiRelevantStatus(false, "", selectedTab),
			]
		}
	}, [preview, selectedTab, selectedTabSysAdmin, readyForCustomer, companyUuid])

	return showAlerts ? (
		// <React.Fragment>
			<Grid item xs={12} container spacing={2}>
				<Grid item xs={12} sx={{ position: "relative" }}>
					<FeedTable
						alerts={
							getAlerts(
								preview,
								developerAppUuid,
								highlightAlertUuid,
								companyUuid,
								alerts,
								userAlerts,
								userResolvedAlerts,
								sysAdminAlerts,
								sysAdminResolvedAlerts,
							)
						}
						sysAdmin={preview || !!companyUuid}
						dashboardView={true}
						preview={preview}
						currentTab={companyUuid ? selectedTabSysAdmin : selectedTab}
						columns={columns}
					/>
					{!preview && <LastSyncPicker developerAppUuid={developerAppUuid} companyUuid={companyUuid}/>}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h4" /*sx={{ color: theme.palette.primary.dark }}*/>Details</Typography>
				</Grid>
			</Grid>
		// </React.Fragment>
	) : null;
}

export default FDAlertFeed;