import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { generateNewColumnsState, generateSavedColumnsState, setInitialColumnsState } from "../../../store/dashboardTableVizSlice"
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
/*
if companyUuid - sysadmin viewing - generate newColumnsState
if isReport - viewing an old report dashboard - generateNewColumnsState (tables may have changed dramatically since report)
if !isReport - get columnsState - if present, generateSavedColumnsState, otherwise generateNewColumnsState
*/
const GetInitialColumnsState = () => {
    const dispatch = useDispatch();
    const uuid = useSelector(state => state.dashboard.uuid)
    const isReport = useSelector(state => state.dashboard.isReport)
    const user = useSelector(state => state.role.name)
    const companyUuid = useSelector(state => state.dashboard.companyUuid)
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson)

    const [columnsStateArgs, setColumnsStateArgs] = useState(null)

    useEffect(() => {
        if (dashboardJson) {
            console.log("GetInitialColumnsState - useEffect -- dashboardJson found")
            if (companyUuid) {
                console.log("GetInitialColumnsState - useEffect -- companyUuid found - generating new columnsState")
                // generateNewColumnsState
                const initialColumnsState = generateNewColumnsState(dashboardJson)
                dispatch(setInitialColumnsState(initialColumnsState))
            } else if (isReport) {
                console.log("GetInitialColumnsState - useEffect -- isReport - generating new columnsState")
                const initialColumnsState = generateNewColumnsState(dashboardJson)
                dispatch(setInitialColumnsState(initialColumnsState))
            } else {
                console.log("GetInitialColumnsState - useEffect -- !isReport - getting saved columnsState")
                const tempArgs = {
                    url: Constants.SERVER_POST_GET_USER_DASHBOARD_LAYOUT_URL,
                    method: "POST",
                    body: JSON.stringify({
                        developerAppUuid: uuid
                    })
                }
                setColumnsStateArgs(tempArgs)
            }
        }
    }, [uuid, isReport, user, companyUuid, dashboardJson])

    const handleColumnsState = (res) => {
        console.log("GetInitialColumnsState - handleColumnsState -- res", res)
        // res.dashboardLayout.tableSettings is the object we want
        const columnsState = res?.dashboardLayout?.tableSettings
        if (columnsState) { 
            // there are settings
            const initialColumnsState = generateSavedColumnsState(dashboardJson, columnsState)
            dispatch(setInitialColumnsState(initialColumnsState))
        } else {
            const initialColumnsState = generateNewColumnsState(dashboardJson)
            dispatch(setInitialColumnsState(initialColumnsState))
        }
    }

    return (
        <DirectRequest
            requestArgs={columnsStateArgs}
            afterProcess={handleColumnsState}
            handleError={err => {
                console.warn("server error getting columnsState", err)
                handleColumnsState({})
            }}
            handleCatchError={err => {
                console.warn("client error getting columnsState", err)
                handleColumnsState({})
            }}
        />
    )
}

// TODO: debounced request to save adjustments to columnsState
const SaveColumnsState = () => {
    const uuid = useSelector(state => state.dashboard.uuid)
    const isReport = useSelector(state => state.dashboard.isReport)
    const user = useSelector(state => state.role.name)
    const companyUuid = useSelector(state => state.dashboard.companyUuid)
    const columnsState = useSelector(state => state.dashboardTableViz.columnsState)

    const [saveColumnsStateArgs, setSaveColumnsStateArgs] = useState(null)

    useEffect(() => {
        // Don't save if it's a report or company view
        if (isReport || companyUuid) return;
        
        const timeoutId = setTimeout(() => {
            const args = {
                url: Constants.SERVER_POST_SAVE_USER_DASHBOARD_LAYOUT_URL,
                method: "POST",
                body: JSON.stringify({
                    developerAppUuid: uuid,
                    columnsState: columnsState
                })
            };
            setSaveColumnsStateArgs(args);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
            setSaveColumnsStateArgs(null);
        };
    }, [columnsState, uuid, user, isReport, companyUuid]);

    const handleColumnsStateSave = () => {
        console.log("handleColumnsStateSave");
        setSaveColumnsStateArgs(null);
    }

    const handleError = (err) => {
        console.warn("error saving columnsState", err);
        setSaveColumnsStateArgs(null);
    }

    return saveColumnsStateArgs ? (
        <DirectRequest
            requestArgs={saveColumnsStateArgs}
            afterProcess={handleColumnsStateSave}
            handleError={handleError}
            handleCatchError={handleError}
        />
    ) : null;
}

export const DashboardTableVizManager = () => {
    const columnsStateReady = useSelector(state => state.dashboardTableViz.columnsStateReady)
    return Constants.USE_COLUMNS_STATE ? (
        <>
            {!columnsStateReady && <GetInitialColumnsState/>}
            {columnsStateReady && <SaveColumnsState/>}
        </>
    ) : null;
}

export default DashboardTableVizManager;