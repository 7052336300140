import * as Constants from "../../../../Constants";
import iso8601Timestamp from "../../../../helpers/iso8601Timestamp";
import { 
  config_options,
  createEntryConfig_fields,
  sendEmailConfig_fields,
  autoPerformConfig_fields
} from "../../../../store/dashboardConfigsSlice";

export const getPrimaryKeyValueMap = (row, primaryKeyFieldList) => {
  const tempPrimaryKeyValueMap = {};
  primaryKeyFieldList.forEach((primaryKey) => {
    tempPrimaryKeyValueMap[primaryKey] = row[primaryKey];
  })
  // console.log("tempPrimaryKeyValueMap", tempPrimaryKeyValueMap)
  return tempPrimaryKeyValueMap;
}

export const getLinkedTableForeignValueMaps = (row, primaryKeyFieldList, linkedTableForeignFieldMap) => {
  //Map<String, List<String>> linkedTableForeignFieldMap
  const orderedPrimaryValues = primaryKeyFieldList.map((primaryKey) => row[primaryKey]);
  const tempLinkedTableForeignValueMap = {};
  Object.entries(linkedTableForeignFieldMap).forEach(([tableName, foreignFieldMap]) => {
    const tempForeignValueMap = {};
    foreignFieldMap.forEach((field, index) => {
      tempForeignValueMap[field] = orderedPrimaryValues[index];
    })
    tempLinkedTableForeignValueMap[tableName] = tempForeignValueMap;
  })
  // console.log("tempLinkedTableForeignValueMap", tempLinkedTableForeignValueMap);
  return tempLinkedTableForeignValueMap;
}

const getDisplayConfigOptionValue = (configAction, newConfigValue) => {
  console.log("getDisplayConfigOptionValue - configAction", configAction, "newConfigValue", newConfigValue);
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    return entryUuid;
  } else if (configAction === config_options.send_email) {
    return "";
  } else {
    return newConfigValue;
  }
}

export const generateDisplayConfigUpdateArgs = (companyUuid, newConfigValue, configAction, row, displayConfigUuid, entryPeriod, primaryKeyFieldList, linkedTableForeignFieldMap, statusTrackerConfig) => {
  // TODO: needs to handle optional parameters if configAction === config_options.create_entry
  const configUpdateArgsArray = [];
  const tempBody = {
    displayConfigTableUuid: displayConfigUuid,
    primaryKeyValueMap: getPrimaryKeyValueMap(row, primaryKeyFieldList),
    displayConfigOptionName: configAction,
    displayConfigOptionValue: getDisplayConfigOptionValue(configAction, newConfigValue),// NO CONFIG ROW YET - means false, FLIP TO TRUE
    entryPeriod: entryPeriod
  }
  if (linkedTableForeignFieldMap) {
    tempBody["linkedTableForeignValueMaps"] = getLinkedTableForeignValueMaps(row, primaryKeyFieldList, linkedTableForeignFieldMap);
  }
  if (configAction === config_options.create_entry || configAction === config_options.send_email) {
    const { rowConfigSetupInfo } = newConfigValue
    tempBody["rowConfigSetupInfo"] = rowConfigSetupInfo;
  }
  const tempUpdateArgs = {
    url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,// TODO - add
    method: "POST",
    body: JSON.stringify(tempBody)
  }
  //return tempUpdateArgs;
  configUpdateArgsArray.push(tempUpdateArgs);
  /* 
    We're not done! Now we need to check statusTrackerConfig.allowedStatusActionMap 
    because if the configAction is in the map - we need to produce a second set of args 
    following the same logic as above except only for status_tracker config action (config_options.status_tracker) 
  */
  if (statusTrackerConfig) {
    if (statusTrackerConfig.allowedStatusActionMap) {
      if (configAction === config_options.create_entry && newConfigValue["entryUuid"] === "NOT_SET") {
        // don't send status update on entry deletion - server handles
      } else if (Object.keys(statusTrackerConfig.allowedStatusActionMap).includes(configAction) && row["_status_tracker"]) {
        // ok we can see current status row["_status_tracker"] - use this to figure out which status to chenge it to
        const tempStatusUpdateBody = {
          displayConfigTableUuid: displayConfigUuid,
          primaryKeyValueMap: getPrimaryKeyValueMap(row, primaryKeyFieldList),
          displayConfigOptionName: config_options.status_tracker,
          entryPeriod: entryPeriod
        }
        if (linkedTableForeignFieldMap) {
          tempStatusUpdateBody["linkedTableForeignValueMaps"] = getLinkedTableForeignValueMaps(row, primaryKeyFieldList, linkedTableForeignFieldMap);
        }
        const newStatus = statusTrackerConfig.allowedStatusActionMap[configAction][row["_status_tracker"]];
        tempStatusUpdateBody["displayConfigOptionValue"] = newStatus;
        console.log("tempStatusUpdateBody", tempStatusUpdateBody);
        // now construct rest of args
        const tempStatusUpdateArgs = {
          url: companyUuid ? Constants.SERVER_SYSADMIN_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL + companyUuid : Constants.SERVER_POST_DISPLAY_CONFIG_CREATE_OR_MODIFY_ROW_URL,// TODO - add
          method: "POST",
          body: JSON.stringify(tempStatusUpdateBody)
        }
        configUpdateArgsArray.push(tempStatusUpdateArgs)
      }
    }
  }
  console.log("configUpdateArgsArray", configUpdateArgsArray)
  return configUpdateArgsArray;
}

export const objInBody = (obj) => {
  try {
    // Stringify it with nice formatting
    const formattedJSON = JSON.stringify(obj, null, 2);
    // Wrap in pre tags and style for email readability
    return `
      <pre style="
        background-color: #f5f5f5;
        padding: 10px;
        border-radius: 4px;
        font-family: monospace;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 14px;
        color: #333;
        margin: 10px 0;
      ">${formattedJSON}</pre>
    `;
  } catch (e) {
    // Handle case where the obj can't be valid JSON
    return `<p style="color: #666;">Unable to stringify obj: ${obj}</p>`;
  }
}

const getNotificationBody = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const rowInBody = objInBody(row);//Object.entries(row).map(([key, value]) => `${key}: ${value}<br/>`).join("");
  if (configAction === config_options.create_entry) {
    const { entryUuid, rowConfigSetupInfo } = newConfigValue;
    const rowConfigSetupInfoInBody = objInBody(rowConfigSetupInfo);
    const deleted = entryUuid === "NOT_SET";
    return `<strong>${user}</strong> ${deleted ? "deleted entry" : entryUuid ? "updated entry " + entryUuid : "created entry"}<br/><br/>
      displayConfigUuid: ${displayConfigUuid}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      rowConfigSetupInfo: ${rowConfigSetupInfoInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
  } else if (configAction === config_options.send_email) {
    const { rowConfigSetupInfo } = newConfigValue;
    const rowConfigSetupInfoInBody = objInBody(rowConfigSetupInfo);
    return `<strong>${user}</strong> sent email<br/><br/>
      displayConfigUuid: ${displayConfigUuid}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      rowConfigSetupInfo: ${rowConfigSetupInfoInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
  } else {
    return `<strong>${user}</strong> set ${configAction} to ${newConfigValue}<br/><br/>
      display config uuid: ${displayConfigUuid}<br/><br/>
      appUuid: ${devAppUuid}<br/><br/>
      row: ${rowInBody}<br/><br/>
      on ${window.location.origin}<br/><br/>`
  }
}

const getNotificationSubject = (newConfigValue, configAction, user) => {
  if (configAction === config_options.create_entry) {
    const { entryUuid } = newConfigValue;
    const deleted = entryUuid === "NOT_SET";
    return `${user} ${deleted ? "deleted entry" : entryUuid ? "updated entry " + entryUuid : "created entry"} (${window.location.origin} - ${iso8601Timestamp()})`
  } else if (configAction === config_options.send_email) {
    return `${user} sent email (${window.location.origin} - ${iso8601Timestamp()})`
  } else {
    return `${user} set ${configAction} to ${newConfigValue} (${window.location.origin} - ${iso8601Timestamp()})`
  }
}

export const generateDisplayConfigUpdateNotification = (newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid) => {
  const tempArgs = {
    url: Constants.SERVER_SEND_EMAIL,
    method: "POST",
    body: JSON.stringify({
      "body": getNotificationBody(newConfigValue, configAction, row, user, displayConfigUuid, devAppUuid),
      "subject": getNotificationSubject(newConfigValue, configAction, user),
      "from": "noreply@wiselayer.com",
      "toEmail": Constants.notificationList.join(","),
      "sendHTML": true
    })
  }
  return tempArgs;
}

export const isRowSelectable = (configs, row) => {
  const {
    statusTrackerConfig,
    createEntryConfig,
    sendEmailConfig,
    hide_row_toggle,
    disableActions
  } = configs
  
  // do not allow row selection if disableActions is true
  if (disableActions) {
    return false;
  }
  
  // do not allow row selection when viewing hidden rows (dashboardFilters.displayConfigsToggleState.hide_row)
  if (!hide_row_toggle) {
    return false;
  }
  
  const entry_fields = createEntryConfig_fields;
  const email_fields = sendEmailConfig_fields;
  const auto_perform_fields = autoPerformConfig_fields;
  
  const createEntryAutoPerform = createEntryConfig?.[entry_fields.autoPerformConfig]
  const sendEmailAutoPerform = sendEmailConfig?.[email_fields.autoPerformConfig]
  
  // do not allow row selection if multiple autoPerform configs - this is erroneous script logic and needs to be corrected in the app scripts
  if (createEntryAutoPerform && sendEmailAutoPerform) {
    return false;
  }
  
  if (createEntryAutoPerform) {
    const autoPerformFlag = row[createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
    const validStatus = createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
    
    if (autoPerformFlag && validStatus) {
      return true;
    }
  }
  /*
  createEntry:
  console.log("checking row for autoCreateEntry", row);
  const displayConfigTableRow = displayConfigTableRows
    ?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap)
    .every(([field, value]) => row[field] === value));
  
  const hidden = displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.["optionValue"];
  const autoPerformFlag = row[createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
  const validStatus = createEntryConfig[entry_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
  */
  
  if (sendEmailAutoPerform) {
    const autoPerformFlag = row[sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
    const validStatus = sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
    
    if (autoPerformFlag && validStatus) {
      return true;
    }
  }
  /*
  sendEmail:
  console.log("checking row for autoSendEmail", row);
  const displayConfigTableRow = displayConfigTableRows
    ?.find((displayConfigTableRow) => Object.entries(displayConfigTableRow.primaryKeyValueMap)
    .every(([field, value]) => row[field] === value));
  
  const hidden = displayConfigTableRow && displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.["optionValue"];
  const autoPerformFlag = row[sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.autoPerformFlagColumn]];
  const validStatus = sendEmailConfig[email_fields.autoPerformConfig][auto_perform_fields.validAutoPerformStatusList].includes(row["_status_tracker"]);
  */
  
  // default to false
  return false;
  
}