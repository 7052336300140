import { Grid, Autocomplete, TextField } from "@mui/material";
import { form_fields } from "./createEntryForm";

const PostingPeriodInput = ({ formState, setFormState }) => {
  return (
    <Grid item xs={3} sx={{ mb: 2 }}>
      <Autocomplete
        value={formState.postingPeriod || Object.keys(formState[form_fields.periodNameToIdMap])[0]}
        disableClearable
        clearOnEscape={false}
        options={Object.keys(formState[form_fields.periodNameToIdMap])}
        onChange={(event, newValue) => {
          setFormState({
            ...formState,
            postingPeriod: newValue
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="standard"
          />
        )}
      />
    </Grid>
  );
};

export default PostingPeriodInput; 