import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import { 
    updateColumnsStateColumnOrder, 
    updateColumnsStateColumnSettings,
    updateSysAdminState,
    sysAdminUpdatePayload,
    sysAdmin_update_types,
    generateNewColumnsState,
    generateSavedColumnsState,
} from '../../../../store/dashboardTableVizSlice';
import DirectRequest from '../../../../API/requests/DirectRequest';
import * as Constants from '../../../../Constants';

const DraggableColumn = ({ column, index, moveColumn, objectName, columnSettings }) => {
    const dispatch = useDispatch();
    const [{ isDragging }, drag] = useDrag({
        type: 'column',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: 'column',
        hover: (item, monitor) => {
            if (item.index === index) {
                return;
            }
            moveColumn(item.index, index);
            item.index = index;
        },
    });

    const isVisible = columnSettings[column.field]?.visible ?? true;

    const handleVisibilityToggle = (event) => {
        event.stopPropagation(); // Prevent drag from starting when clicking the button
        dispatch(updateColumnsStateColumnSettings({
            objectName,
            field: column.field,
            option: 'visible',
            value: !isVisible
        }));
    };

    return (
        <Box
            ref={(node) => drag(drop(node))}
            sx={{
                p: 1,
                mb: 1,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                cursor: 'move',
                opacity: isDragging ? 0.5 : 1,
                '&:hover': {
                    bgcolor: 'action.hover',
                },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Typography>{column.headerName}</Typography>
            <IconButton 
                onClick={handleVisibilityToggle}
                size="small"
                sx={{ ml: 1 }}
            >
                {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
        </Box>
    );
};

const SysAdminAlertChip = () => {
    const noSavedColumnsState = useSelector(state => state.dashboardTableViz.sysAdmin.noSavedColumnsState);
    const savedColumnsState = useSelector(state => state.dashboardTableViz.sysAdmin.savedColumnsState);
    const user = useSelector(state => state.dashboardTableViz.sysAdmin.user);
    

    /*
    <Chip
			label=<span><strong>ERROR:</strong> autoPerformConfig found in sendEmailConfig and createEntryConfig</span>
			color="error"
			sx={{
				height: "24px", // Small button default height
				fontSize: "10px",
				marginRight: "14px",
				padding: "0px 12px",
				"& .MuiChip-label": {
					padding: "0",
					whiteSpace: "nowrap"
				}
			}}
		/>
    */
    if (noSavedColumnsState) {
        return (
            <Chip
                label={`No settings for ${user}`}
                color="error"
                sx={{
                    height: "18px",
                    fontSize: "10px",
                    padding: "0px 10px",
                    "& .MuiChip-label": {
                        padding: "0",
                        whiteSpace: "nowrap"
                    }
                }}
            />
        )
    } else if (savedColumnsState) {
        return (
            <Chip
                label={`Settings for ${user}`}
                color="success"
                sx={{
                    height: "18px",
                    fontSize: "10px",
                    padding: "0px 10px",
                    "& .MuiChip-label": {
                        padding: "0",
                        whiteSpace: "nowrap"
                    }
                }}
            />
        )
    } else {
        return null;
    }
}

const SysAdminUserSettingsInput = () => {
    const dispatch = useDispatch();
    const companyUuid = useSelector(state => state.dashboard.companyUuid);
    const isSysAdmin = useSelector(state => state.role.isSysAdmin);
    const uuid = useSelector(state => state.dashboard.uuid);
    const dashboardJson = useSelector(state => state.dashboard.dashboardJson);

    const user = useSelector(state => state.dashboardTableViz.sysAdmin.user);
    const sysAdminUserSettingsArgs = useSelector(state => state.dashboardTableViz.sysAdmin.args);

    const getSysAdminUserSettings = () => {
        const tempArgs = {
            url: Constants.SERVER_SYSADMIN_POST_GET_DASHBOARD_LAYOUT_URL + companyUuid,
            method: "POST",
            body: JSON.stringify({
                developerAppUuid: uuid,
                layoutUser: user
            })
        }
        dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.args, tempArgs)));
    }

    const handleSysAdminUserSettings = (res) => {
        const columnsState = res?.dashboardLayout?.tableSettings
        if (columnsState) {
            const newColumnsState = generateSavedColumnsState(dashboardJson, columnsState)
            dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.columnsState, newColumnsState)));
        } else {
            dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.noSavedColumnsState, true)));
        }
        
    }

    const handleError = (err) => {
        console.error("Error getting sys admin user settings", err);
    }

    const handleCancel = () => {
        const initialColumnsState = generateNewColumnsState(dashboardJson)
        dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.reset, initialColumnsState)));
    }

    return (isSysAdmin && companyUuid) ? (
        <>
            <DirectRequest
                requestArgs={sysAdminUserSettingsArgs}
                afterProcess={handleSysAdminUserSettings}
                handleError={handleError}
                handleCatchError={handleError}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Add this
                    width: '100%',
                    pb: 1,
                    gap: 1,
                }}
            >
                <Box
                    // needs to be full width of container and flex - will hold textfield and iconbuttons -
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    // Textfield needs to take all available space not used by iconbutton
                >
                    <TextField
                        //sx={{ width: "90%" }}
                        sx={{ flexGrow: 1 }}
                        size="small"
                        label="User"
                        value={user}
                        onChange={(e) => {
                            dispatch(updateSysAdminState(sysAdminUpdatePayload(sysAdmin_update_types.user, e.target.value)));
                        }}
                        disabled={sysAdminUserSettingsArgs}
                    />
                    {!sysAdminUserSettingsArgs &&
                        <IconButton
                            onClick={getSysAdminUserSettings}
                            sx={{ padding: "4px" }}
                            disabled={!user}
                        >
                            <CheckIcon />
                        </IconButton>
                    }
                    {sysAdminUserSettingsArgs &&
                        <IconButton
                            onClick={handleCancel}
                            sx={{ padding: "4px" }}
                        >
                            <CancelIcon />
                        </IconButton>
                    }
                </Box>
                <SysAdminAlertChip />
            </Box>
        </>
    ) : null;
}

const TableSettings = ({ columns, objectName, columnsState }) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    // Filter columns to only show non-hidden, non-pinned columns
    const [reorderableColumns, setReorderableColumns] = useState(
        columns.filter(col => 
            !col.hidden && 
            !(columnsState?.pinnedColumns?.left?.includes(col.field)) &&
            !(columnsState?.pinnedColumns?.right?.includes(col.field))
        )
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const moveColumn = useCallback((dragIndex, hoverIndex) => {
        const dragColumn = reorderableColumns[dragIndex];
        const newColumns = [...reorderableColumns];
        newColumns.splice(dragIndex, 1);
        newColumns.splice(hoverIndex, 0, dragColumn);
        setReorderableColumns(newColumns);
        
        // Update Redux store with new column order
        dispatch(updateColumnsStateColumnOrder({
            objectName,
            value: newColumns.map(col => col.field)
        }));
    }, [reorderableColumns, dispatch, objectName]);

    return Constants.USE_COLUMNS_STATE ? (
        <>
            <IconButton onClick={handleClick} sx={{ padding: "4px"}}>
                <SettingsIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    vertical: 'top',
                }}
            >
                <Paper sx={{ p: 2, width: 300 }}>
                    <SysAdminUserSettingsInput />
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Columns
                    </Typography>
                    <DndProvider backend={HTML5Backend}>
                        {reorderableColumns.map((column, index) => (
                            <DraggableColumn
                                key={column.field}
                                column={column}
                                index={index}
                                moveColumn={moveColumn}
                                objectName={objectName}
                                columnSettings={columnsState?.columnSettings || {}}
                            />
                        ))}
                    </DndProvider>
                </Paper>
            </Popover>
        </>
    ) : null;
};

export default TableSettings; 